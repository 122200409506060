<template>
    <div class="follow-btn">
        <div class="follow" :class="{fire:!follow,is_big:is_big}" @click="changeFollow">
                {{follow?'已关注':'关注'}}
            </div>
    </div>
</template>

<script>
import Api from '@/api'

export default {
  name: 'PostItem',
  props: {
    user_id: Number,
    is_follow: Boolean,
    is_big:{type:Boolean,default:false}
  },
  data(){
      return {
          follow:this.is_follow
      }
  },
  methods:{
      
      changeFollow(){
          if(this.follow){
              this.follow=false;
              Api.go('changeFollow',{t:0,id:this.user_id},(res)=>{})
          }else{
              
              this.follow=true;
              Api.go('changeFollow',{t:1,id:this.user_id},(res)=>{})
          }
      },
  }
}
</script>

<style scoped>
.follow{line-height: 0.42rem;background: rgb(244,245,247);padding:0 0.2rem;border-radius: .24rem;font-size: .24rem;color:#999;
    word-break: keep-all;
}
.follow.fire{background: #F65102;color:#fff;font-weight: 400;}
.follow.is_big{line-height: 0.6rem;    border-radius: 0.3rem;font-size: 0.28rem;}
</style>