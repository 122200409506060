<template>
    <div class="nav-bar-wp">
        <div class="nav-bar-block"></div>
        <div class="nav-bar">
            <router-link to="/"
                :class="{fire:nav=='home'}"
                class="home"
            >首页</router-link>
            <router-link to="/msg"
                :class="{fire:nav=='msg'}"
                class="message"
            >消息</router-link>
            <router-link to="/my"
                :class="{fire:nav=='my'}"
                class="my"
            >我的</router-link>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    nav: String
  }
}
</script>

<style scoped>
.nav-bar-block{height:1.1rem;}
.nav-bar{height: 1rem;box-shadow: 0 0 5px #999999;display: flex;
position:fixed;bottom: 0;left: 0;width: 100%;flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;background:#fff
}
.nav-bar .fire{color: #F65102}

.nav-bar a{padding-top:0.48rem;font-size: 0.2rem;line-height: 0.4rem;color: #777;}
.nav-bar a.home{background:url(~@/assets/img/nav-bar-home.png) no-repeat center top /auto 0.48rem;}
.nav-bar a.home.fire{background-image:url(~@/assets/img/nav-bar-home-fire.png);}
.nav-bar a.message{background:url(~@/assets/img/nav-bar-msg.png) no-repeat center top /auto 0.46rem;}
.nav-bar a.message.fire{background-image:url(~@/assets/img/nav-bar-msg-fire.png)}
.nav-bar a.my{background:url(~@/assets/img/nav-bar-my.png) no-repeat center top /auto 0.48rem;}
.nav-bar a.my.fire{background-image:url(~@/assets/img/nav-bar-my-fire.png)}
</style>