<template>
    <div class="post-item" :class="{'post-item-desc':isDescPage}">
        <!-- 官方 -->
         <div class="official_top" style="display:none;">
            <div class="avatar" :style="{'background-image':'url('+item_.user.headimgurl+')'}"></div>
            <div class="nickname"><div>田园橙柿</div></div>
            <div class="topping">
                <img src="~@/assets/img/topping.png">
                置顶
            </div>
        </div>

        <div class="user-bar" v-if="hideUserBar==false">
            <div class="avatar" @click="goUser(item_.user.id)" :style="{'background-image':'url('+item_.user.headimgurl+')'}"></div>
            <div class="nickname" @click="goUser(item_.user.id)">
                <div>{{item_.user.title}}</div>
                <span class="goaltitle">{{item_.user.goalTitle.thisLv.title}}</span>
            </div>
            <div class="follow"  v-if="item_.user.isCanFollow" >
                <FollowBtn :user_id="item_.user.id" :is_follow="isFollow" />
            </div>
        </div>
        <div class="title" @click="goDesc">
            <strong v-if="item_.title">{{item_.title}}</strong>
             
            <div v-html="item_.body" class="post-body" :class="{descbody:isDescPage}"></div>
            <!-- <img src="~@/assets/img/link.png">
                查看详情 -->
        </div>
        <!-- <div class="body" v-html="item_.body" @click="goDesc"></div> -->
        <div class="imgs" v-if=" item_.post_type==1 && item_.imgs.length>0" :class="{
            'imgs-size-1':item_.imgs.length==1,
            'imgs-size-2':item_.imgs.length==2,
            'imgs-size-3':item_.imgs.length==3,
            'imgs-size-4':item_.imgs.length==4,
            'imgs-size-5':item_.imgs.length==5,
            'imgs-size-6':item_.imgs.length==6,
            'imgs-size-7':item_.imgs.length==7,
            'imgs-size-8':item_.imgs.length==8,
            'imgs-size-9':item_.imgs.length==9,
        }"  @click="openImgPreview()">
            <div class="img" v-for="img in item_.imgs" :key="'img'+img.id" 
            :style="{'background-image':'url('+img.thumb+')'}">
            </div>
        </div>
        <div class="post-body-video" v-if="item_.post_type==2">
            <video controls :src="item_.video_src"></video>
        </div>
        <div class="post-body-audio" v-if="item_.post_type==3">
            <audio controls :src="item_.audio_src"></audio>
        </div>
        <div class="post-body-link" v-if="item_.post_type==4">
            <a :href="item_.link.src"><img src="~@/assets/img/link.png">{{item_.link.title?item_.link.title:'查看详情'}}</a>
           
        </div>
        <ImagePreview :imgs="item_.imgs" v-if="isImgPreviewShow" @close="closeImgPreview()" />
        <div class="topics">
            <div class="topics_wrap">
                <div v-for="_ in item_.topics" :key="'topics'+_.id" @click="goTopic(_.id)" class="type">
                 #{{_.title}}#
                </div>
            </div>
            <!-- 官方 -->
            <div class="item_more" style="display:none;"></div>
        </div>
        <div class="send_adr" v-if="item_.locale_title"> 
                <img src="~@/assets/img/send-adr.png">
                {{item_.locale_title}}
            </div>
        <div class="cmd-bar">
            <div class="cmd-bar-fl">
                <div class="item item-fav" :class="{fire:isFav}" @click="changeFav">
                    {{favNum}}
                </div>
                <div class="item item-comment" @click="goDesc">
                    {{commentNum}}
                </div>
                <div class="item item-like" :class="{fire:isLike}" @click="changeLike">
                    {{likeNum}}
                </div>
            </div>
            <div class="item_more" @click="showMore"></div>
            <!-- 更多的弹窗 -->
            <div class="item_more_tc" v-show="isShowMore">
                <div @click="$router.push('/Complaint/'+item_.id)" ><img  src="~@/assets/img/comment-ico1.png">投诉</div>
                <div><img src="~@/assets/img/comment-ico2.png">生成图片</div>
            </div>
        </div>
        <!-- 详情页 -->
        <div class="comments-wp" v-if="item_.comments.length>0">
            <div v-for="(comment,comment_index) in item_.comments" :key="'comment'+comment.id">
                <div @click="choseReply(comment.id)" v-if="isDescPage || comment_index<2" class="comment_tit">
                    {{comment.user.title}}:
                    <span v-if="comment.reply_id>0">回复{{comment.reply.title}}</span>
                    <span v-html="comment.msg"></span>
                </div>
            </div>
            <!-- <div class="more_comment">查看更多 ></div> -->
        </div>
        <div class="submit-comment" v-if="isDescPage">
            <div class="comment-bar">
                <div class="comment-block"></div>
                <div class="comment-item">
                    <div class="to" v-if="commentReplyUser==null">发布评论：</div>
                    <div class="to" v-if="commentReplyUser">回复：{{commentReplyUser.title}}<span @click="commentReplyUser=null">取消回复</span></div>
                    <div class="line">
                        <input type="text" v-model="comment" style="font-size:0.3rem">
                        <span @click="submitComment">发布</span> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ImagePreview from '@/components/ImagePreview.vue'
import FollowBtn from '@/components/FollowBtn.vue'
import Vue from 'vue'
import Api from '@/api'

export default {
  name: 'PostItem',
  props: {
    item: Object,
    isDescPage: Boolean,
    hideUserBar:{type:Boolean,default:false}
  },
  data(){
      return {
          item_: this.item,
          isImgPreviewShow:false,
          isFollow:this.item.user.isFollow,
          isFav:this.item.isFav,
          isLike:this.item.isLike,
          favNum:this.item.favNum,
          likeNum:this.item.likesNum,
          comment:'',
          commentNum:this.item.commentsNum,
          commentAll:this.item.comments,
          commentReplyUser:null,
          isShowMore:false, //显示投诉，生成图片
      }
  },  
  components: {
    ImagePreview,
    FollowBtn
  },
  methods:{
      showMore(){
          this.isShowMore = true
      },
      goTopic(id){
        this.$router.push('/topic/'+id)
      },
      goUser(id){
        this.$router.push('/user/'+id)
      },
      choseReply(id){

          if(this.isDescPage){

            for(var i in this.commentAll){
                if(this.commentAll[i].id==id){
                this.commentReplyUser=this.commentAll[i].user;
                }
            }
          }else{
              this.goDesc()
          }
      },
      submitComment(){
        var that=this
        Api.go('create_comment',{msg:this.comment,reply:this.commentReplyUser?this.commentReplyUser.id:0,id:this.item_.id},(res)=>{
            that.commentNum++
            that.comment=''
            that.commentAll.push(res.comment)
        })
      },
      goDesc(){
          if(this.isDescPage){

          }else{

            this.$router.push('/post/'+this.item_.id)
          }
      },
      changeFav(){
          if(this.isFav){
              this.isFav=false;
              this.favNum--;
              Api.go('changeFav',{t:0,id:this.item_.id},(res)=>{})
          }else{
              this.favNum++;
              this.isFav=true;
              Api.go('changeFav',{t:1,id:this.item_.id},(res)=>{})
          }
      },
      changeLike(){
          if(this.isLike){
              this.likeNum--;
              this.isLike=false;
              Api.go('changeLike',{t:0,id:this.item_.id},(res)=>{})
          }else{
              this.likeNum++
              this.isLike=true;
              Api.go('changeLike',{t:1,id:this.item_.id},(res)=>{})
          }
      },
      changeFollow(){
          if(this.isFollow){
              this.isFollow=false;
              Api.go('changeFollow',{t:0,id:this.item_.user.id},(res)=>{})
          }else{
              
              this.isFollow=true;
              Api.go('changeFollow',{t:1,id:this.item_.user.id},(res)=>{})
          }
      },
      openImgPreview(){
          //this.isImgPreviewShow=true
     
      },
      closeImgPreview(){
          this.isImgPreviewShow=false
      }
  }
}
</script>

<style lang="less" scoped>
.post-item{background: #fff;border-bottom: 0.3rem solid rgb(248,248,250);padding:0.12rem .32rem .24rem}
.post-item .user-bar{display: flex;position: relative; align-items: center;}
.avatar{width:0.8rem;height: 0.8rem;border-radius:50%;overflow: hidden; background-size: cover; margin-right: .16rem;}
.nickname{display: flex;
    flex-direction: column;
    align-items: flex-start;}
.nickname div{
    font-size: .32rem;
    font-weight: 400;
    color: #333333;
}
.nickname .goaltitle{
    font-size: .22rem;
    font-weight: 400;
    color: #F65102;
    border-radius: .16rem;
    border: .02rem solid #F65102;
    padding: 0 .2rem;
}
.official_top{
    display: flex;
    align-items: center;
    position:relative;
    .nickname div{
        color: #F65102;
    }
    .topping{
        width: .88rem;
        height: .34rem;
        background: #EC3A40;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .24rem;
        color: #fff;
        position: absolute;
        top: -.12rem;
        right: 1.04rem;
        img{
            width: .2rem;
            height: .2rem;
        }
    }
}
.post-item .user-bar .follow{position: absolute;right: 0;top:50%;transform:translateY(-50%) ; }
.post-item .body{max-height: 0.8rem;line-height: 0.4rem;overflow: hidden;margin:0.2rem 0}
/* .post-item .title{max-height: 0.4rem;line-height: 0.4rem;overflow: hidden;margin:0.2rem 0;} */
.post-item .title{
    font-size: .28rem;
    color: #333; 
   /* font-weight: 400;
    display: flex;*/
    margin: .12rem 0 0;
    /*
    div{
        color: #F65102;
        img{
            width: .24rem;
            height: .24rem;
        }
    }*/
    strong{
        font-weight: bolder;display: block;
    }

}
.post-item .imgs{display: flex;justify-content: space-between;margin: .24rem 0;}
.post-item .imgs .img{background-size: cover;border:1px solid #ccc;height: 2rem;width:31%;background-position: center center;background-repeat: no-repeat;}
.post-item .imgs .img img{max-width: 100%;max-height: 100%;}
.post-item .imgs.imgs-size-1 .img{width: 99%;height: 4rem;}
.post-item .imgs.imgs-size-2 .img{width: 47%;height: 3rem;}
.post-item .imgs.imgs-size-4 .img{width: 47%;height: 2rem;}
.post-item .topics{
    display: flex;
    justify-content: space-between;
}
.post-item .topics .topics_wrap{display: flex;flex-wrap: wrap;}
.post-item .topics .type{height: 0.4rem;line-height: 0.4rem;background: rgb(244,245,247);padding:0 0.2rem;border-radius: 0.4rem;text-align: center;
margin:0.1rem 0.1rem 0.1rem 0;white-space:nowrap;font-size: 0.26rem;
}
.post-item .cmd-bar{display: flex;align-items: center; justify-content: space-between; margin:0.2rem 0;position: relative;}
.post-item .cmd-bar .cmd-bar-fl{
    width: 6rem;
    display: flex;
}
.post-item .cmd-bar .item{width:20%;padding-left:0.5rem;font-size: .26rem;color: #777;}
.post-item .cmd-bar .item-fav{background:url(~@/assets/img/fav.png) no-repeat left center /auto 100%}
.post-item .cmd-bar .item-fav.fire{background-image:url(~@/assets/img/fav-fire.png)}
.post-item .cmd-bar .item-like{background:url(~@/assets/img/like.png) no-repeat left center /auto 100%}
.post-item .cmd-bar .item-like.fire{background-image:url(~@/assets/img/like-fire.png)}
.post-item .cmd-bar .item-comment{background:url(~@/assets/img/comment.png) no-repeat left center /auto 100%}
.item_more{
    float: right;
    width: .4rem;
    height: .4rem;
    background:url(~@/assets/img/more.png) no-repeat left center /auto 100%;
    
}
.item_more_tc{
    width: 2.36rem;
    height: 1.68rem;
    background: url(~@/assets/img/more-wrap.png) no-repeat right center /auto 100%;
    position: absolute;
    bottom: .1rem;
    right: -.05rem;
    padding:0  .3rem;
    box-sizing: border-box;
    div{
        font-size: .26rem;
        color: #777;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:.16rem 0;
        border-bottom: 1px solid #e8e8e8;
        img{
            width: .36rem;
            height: .36rem;
        }
        &:last-child{
            border-bottom: none;
        }
    }
}
.post-item-desc.title,
.post-item-desc .body{max-height: none;}
.post-item .comment-block{height: 1rem;}
.post-item .comment-item{
    // height: 1rem;
    position: fixed;
    bottom:0;
    left: 0;
    width: 100%;
    background:#fff;
    padding:.3rem 0;
    .to{
        height: 0.3rem;
        line-height: 0.3rem;
        padding:.2rem 0.32rem;
        font-size: 0.24rem;
        span{
            margin-left: 0.4rem;
            background:#ccc;
            padding:0 0.3rem;
            border-radius:8px;
        }
    }
    .line{
        height: 0.62rem;
        line-height: 0.62rem;
        padding:0 0.32rem;
        display: flex;
        justify-content: space-between;
        input{
            background: #FFF6F1;
            width:5rem;
            border-radius: .3rem;
            padding:0 0.2rem ;
        }
        span{
            background:#F65102;
            color:#fff;
            border-radius: .3rem;
            width: 1.3rem;
            height: .62rem;
            font-size: .28rem;
            text-align: center;
        }
    }
}

.post-item .comments-wp{margin-top:0.2rem;border-top:1px solid #ccc;padding-top:0.2rem}
.comment_tit{
    font-size: .26rem;
    color: #333;
    font-weight: 400;
    i{
        color: #777;
    
    }
}
.more_comment{
    font-size: .22rem;
    font-weight: 400;
    color: #aaa;
    text-align: center;
    margin-top: .2rem;
}
.post-body{line-height: 0.4rem;max-height: 0.8rem;overflow: hidden;}
.post-body.descbody{max-height: auto;}
.post-body-video,
.post-body-audio{width: 100%;margin-top:0.3rem}
.post-body-video video,.post-body-audio audio{width: 100%;}
//.post-body-link{margin-top:0.3rem;} 
.post-body-link a{font-size: 0.3rem;color:#EC3A40;}
.post-body-link img{height: 0.3rem;}
.send_adr{
    min-width: 1.96rem;
    
    display: inline-block;
    padding: 0 0.3rem;
    width: auto;
    height: .64rem;
    line-height: .64rem;
    background: #F2F5F7;
    border-radius: .32rem;
    font-size: .24rem;
    color: #666;
    font-weight: 400;
    
    align-items: center;
    justify-content: center;
    img{
        width: .26rem;
        height: .32rem;
        margin-right: .05rem;
    }
}

</style>