import axios from 'axios'
import Qs from 'qs'
import Vue from 'vue'
var HTTP = axios.create({
    baseURL:'https://tycs-admin.bianguo.com.cn/index.php?s=api/', 
    
    withCredentials:true,
    transformRequest: [function (data) {
        data.token=localStorage.getItem('token')
        data = Qs.stringify(data);
        return data;
    }]
});
var wxfun=[];
var iswxready=false;
var iswxload=false;
function wxgo(fun){
    if(iswxready){
        fun()
    }else{
        wxfun.push(fun)
        if(iswxload){

        }else{
            iswxload=true;
            go('wxjs',{
                url:location.href.split('#')[0]
            },function(dd){
                console.log(dd)
                var wxarg={
                    debug: false,
                    appId: 'wxae65bc529e193cdf',
                    timestamp:dd.timestamp , 
                    nonceStr:dd.noncestr, 
                    signature: dd.signature,
                    jsApiList: ['getLocation','startRecord','stopRecord','uploadVoice',
                    'hideOptionMenu','onMenuShareTimeline','onMenuShareAppMessage','onMenuShareQQ','onMenuShareWeibo','onMenuShareQZone','updateAppMessageShareData','updateTimelineShareData'
                ] 
                };
                console.log('jsapi',wxarg.jsApiList)
                wx.config(wxarg);
                wx.ready(function(){
                    iswxready=true;
                    console.log('wx-ready',wxfun)
                    for(var i in wxfun){
                        wxfun[i]()
                    }
                })
            })
        }
    }
}
var loadingCount=0
function loadingOpen(){
    loadingCount++
    if(loadingCount==1){
        Vue.prototype.$loading('loading...');
    }

}
function loadingClose(){
    loadingCount--
    if(loadingCount==0){
        Vue.prototype.$loading.close()
    }
}
function go (fun,data,callback){
    if(!data){
        data={}
    }
    loadingOpen()
    HTTP.post(fun,data)
    .then(function(response){
        loadingClose()
        var data=response.data
        console.log(data)
        if(typeof data=='string'){
            data=JSON.parse(data)
        }
        
        if(data.toast){
            Vue.prototype.$toast(data.toast)
        }else if(data.url){
            localStorage.setItem('urlbefore',location.href)
            location.href=data.url
        }else{

            callback(data)
        }

    }).catch(function(response){
        loadingClose()
        console.log('catch',response)
    });
}
export default{
    go,wxgo
}