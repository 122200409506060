<template>
    <div class="image-preview-x">
        
            <vue-preview  class="image-preview-wp" :slides="slides" @close="handleClose" />
        
    </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    imgs: Array,
  },
  computed:{
      slides(){
          var data=[];
          for(var i in this.imgs){
              data.push({
                  src:this.imgs[i].src,
                  msrc:this.imgs[i].thumb,
                  w: this.imgs[i].size,
                    h: this.imgs[i].size
              })
          }
          return data
      }
  },
  methods:{
      handleClose(){
          this.$emit('close')
      }
  }

}
</script>

<style scoped>
.image-preview-wp,.image-preview-x{position: fixed;width:100%;height: 100%;left: 0;top:0;z-index: 99;background:rgba(0,0,0,0.9)}
</style>