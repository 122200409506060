import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/My.vue')
  },
  {
    path: '/msg',
    name: 'Msg',
    component: () => import('../views/Msg.vue')
  },
  {
    path: '/newpost/:topic?',
    name: 'Newpost',
    component: () => import('../views/Newpost.vue')
  },
  {
    path: '/post/:id',
    name: 'Post',
    component: () => import('../views/Post.vue')
  },
  {
    path: '/topic/:id',
    name: 'Topic',
    component: () => import('../views/Topic.vue')
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/all_topic',
    name: 'AllTopic',
    component: () => import('../views/AllTopic.vue')
  },
  {
    path: '/hot',
    name: 'Hot',
    component: () => import('../views/Hot.vue')
  },
  {
    path: '/my_comment',
    name: 'MyComment',
    component: () => import('../views/MyComment.vue')
  },
  {
    path: '/my_fav',
    name: 'MyFav',
    component: () => import('../views/MyFav.vue')
  },
  {
    path: '/my_like',
    name: 'MyLike',
    component: () => import('../views/MyLike.vue')
  },
  {
    path: '/my_follow',
    name: 'MyFollow',
    component: () => import('../views/MyFollow.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/Notice.vue')
  },
  {
      path:'/integral',
      name:'Integral',
      component: () => import('../views/Integral.vue')
  },
  {
    path:'/seting',
    name:'Seting',
    component: () => import('../views/Seting.vue')
  },
  {
    path:'/nickname',
    name:'Nickname',
    component: () => import('../views/Nickname.vue')
  },
  {
    path:'/choseSex',
    name:'ChoseSex',
    component: () => import('../views/ChoseSex.vue')
  },
  { 
    path:'/complaint/:id',
    name:'Complaint',
    component: () => import('../views/Complaint.vue')
  },
  { 
    path:'/letterList',
    name:'LetterList',
    component: () => import('../views/LetterList.vue')
  },
  { 
    path:'/msgRoom/:toid',
    name:'Letter',
    component: () => import('../views/Letter.vue')
  },
  { 
    path:'/guidePage',
    name:'GuidePage',
    component: () => import('../views/GuidePage.vue')
  },
  { 
    path:'/liketome',
    name:'LikeToMe',
    component: () => import('../views/LikeToMe.vue')
  },
  { 
    path:'/followtome',
    name:'FollowToMe',
    component: () => import('../views/FollowToMe.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: 'demo-sns',
  routes
})

export default router
