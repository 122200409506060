<template>
  <div class="home">
    <div class="home-ad" v-if="showad">
      <!-- <span>3</span> -->
      <div class="icon1"></div>
      <div class="icon2"></div>
      <div class="icon3" @click="hidead()"></div>
      <div class="icon4">{{showadi}}</div>
    </div>
    <vue-scroll class="main-page" :ops="ops"
      @refresh-start="handleRefresh"
      @load-start="handleLoad"
       ref="vs"
    >
        <div class="index-top" v-show="!isShowSearch">
            <div class="top_fl" @click="$router.push('/post/'+homeboard.id)" v-if="homeboard">
                <img src="~@/assets/img/index-top-ico1.png">
                ：{{homeboard.title}}
            </div>
            <div class="top_fr">
                <div @click="showSearch"><img src="~@/assets/img/index-top-search.png"></div>
                <div @click="showMa"><img src="~@/assets/img/index-top-ico2.png"></div>
            </div>
        </div>  
        <!-- 搜索 -->
        <div class="search" v-show="isShowSearch">
            <div class="searchCon">
                <div class="searchBtn"><img src="~@/assets/img/index-top-search.png"></div>
                <input v-model="searchkey" placeholder="搜索微贴"/>
            </div>
            <div class="cancelBtn" @click="cancelBtn">取消</div>
        </div>

  <div v-if="isShowSearch">
      <PostItem v-for="item in search_posts"  :isDescPage="false" :key="'search-posts'+item.id" :item="item" />
  </div>
    <div v-else>
      <div class="count-board">
        <table>
          <tr>
            <td>总用户</td>
            <td>总帖子</td>
            <td>总评论</td>
          </tr>
          <tr>
            <th>{{homeCount.all_user}}</th>
            <th>{{homeCount.all_post}}</th>
            <th>{{homeCount.all_comment}}</th>
          </tr>
        </table>
      </div>
      <div class="homelinks">
        <div class="link link1" @click="linkgo(1)"></div>
        <div class="link link2" @click="linkgo(2)"></div>
        <div class="link link3" @click="linkgo(3)"></div>
        <div class="link link4" @click="linkgo(4)"></div>
      </div>
      <PostItem v-for="item in posts"  :isDescPage="false" :key="'posts'+item.id" :item="item" />
    </div>
    </vue-scroll>
    <Navbar nav="home" />
    <router-link to="/newpost" class="newpost-btn"></router-link>
    <!-- 弹窗 -->
    <!-- 二维码 -->
    <div class="mask erweima" v-show="isShowMa" >
        <div class="erweima_main">
            <div class="erweima_wrap">
                <div>二维码</div>
                <img>
                <p>关注<span>田园橙柿</span></p>
            </div>
            <div class="closeBtn" @click="closeMa"><img src="~@/assets/img/close-fff.png"></div>
        </div>
    </div>
    <!-- 每日打卡 -->
    <div class="mask clock" v-show="everyDayClock">
        <div class="clock_wrap">
            <div class="clock_main">
                <div class="clock_tit">打卡成功</div>
                <div class="clock_num">
                    <p><i></i>累计打卡{{signData.all_days}}天</p>
                    <p><i></i>已连续打卡{{signData.this_days}}天</p>
                </div>
                <div class="clock_txt">今天的成长值已领取</div>
            </div>
            <div class="clockbtn btn1" @click="send"><img src="~@/assets/img/upload-ico.png">发布动态</div>
            <div class="clockbtn" @click="finishBtn">完成打卡</div>
        </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Api from '@/api'
import Vue from 'vue'
import PostItem from '@/components/PostItem.vue'
import ImagePreview from '../components/ImagePreview.vue'

export default {
  name: 'Home',
  data(){
    const ops = {
      vuescroll: {
        mode: 'slide',
        pullRefresh: {
          enable: true
        },
        pushLoad: {
          enable: true,
        }
      }
    };
   
      ops.vuescroll.pullRefresh.tips = {
        deactive: '下拉刷新',
        active: '释放刷新',
        start: '刷新中...',
        beforeDeactive: '刷新成功!'
      };
      ops.vuescroll.pushLoad.tips = {
        deactive: '上拉加载',
        active: '释放加载',
        start: '加载中...',
        beforeDeactive: '加载成功!'
      };
    

    return {
       posts:[],
       page:1,
       search_posts:[],
       search_page:1,

       ops:ops,
       showad:true,
       showadi:3,
       isloading:false,
       homeCount:{
         all_user:'...',
         all_post:'...',
         all_comment:'...',
       },
       searchkey:'',
       homeboard:{},
       isShowSearch:false, // 是否显示搜索
       isShowMa:false, // 是否显示二维码
       everyDayClock:false, //每日打卡
       signData:{}
    }
  },
  watch:{
    searchkey(v){
        this.search_page=1;
        this.search_posts=[];
        this.refreshPost()
      
    },
    isShowSearch(v){
      if(this.posts.length==0){

      this.refreshPost()
      }
    }
  },
  components: {
    Navbar,
    PostItem,
    ImagePreview
  },
  methods:{
    //查看公告
    lookNotice(){
        this.$router.push('/Notice')
    },
    //显示搜索
    showSearch(){
        this.isShowSearch = true;
    },
    cancelBtn(){
        this.isShowSearch = false;
    },
    // 显示二维码
    showMa(){
        this.isShowMa = true;
    },
    // 关闭
    closeMa(){
        this.isShowMa = false;
    },
    hidead(){
      this.showad=false
      sessionStorage.setItem('showad',true)
    },
    linkgo(id){
      if(id==1){
        // this.$router.push('/topic/3')
        var that=this
        Api.go('sign_in',false,(res)=>{
          that.signData=res
          that.everyDayClock = true
        })
      }
      if(id==2){
        this.$router.push('/all_topic')
      }
      if(id==3){
        this.$router.push('/hot')
      }
      if(id==4){
        this.$router.push('/topic/4')
      }
    },
    // 完成打卡
    finishBtn(){
        this.everyDayClock = false;
    },
    //发布动态
    send(){
      
        this.$router.push('/newpost/3')
    },
    handleRefresh(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('refresh')
      this.page=1;
      this.posts=[];
      this.refreshPost(done)
    },
    handleLoad(vm, refreshDom, done){
      if(this.isloading){return;}
      console.log('handleLoad')
      this.refreshPost(done)
    },
    refreshPost(cb){
      var that=this
      this.isloading=true
      if(this.isShowSearch){
        if(!this.searchkey){
          return;
        }
          Api.go('searchPost',{page:this.search_page++,key:this.searchkey},(res)=>{
            if(res.key==that.searchkey){
              var _=that.search_posts
              if(res.rows.length>0){
                for(var i in res.rows){
                  _.push(res.rows[i])
                }
              }else{
                Vue.prototype.$toast('暂无更多')
              }
              if(cb){cb();}
              that.isloading=false;
              setTimeout(() => {
                that.$refs.vs.refresh();
              }, 100)
            }
            
          }) 
      }else{
          Api.go('posts',{page:this.page++},(res)=>{
            var _=that.posts
            if(res.length>0){
              for(var i in res){
                _.push(res[i])
              }
            }else{
              Vue.prototype.$toast('暂无更多')
            }
            if(cb){cb();}
            that.isloading=false;
            setTimeout(() => {
              that.$refs.vs.refresh();
            }, 100)
          }) 
      }
      
    }
  },
  mounted(){
    this.refreshPost()
    var that=this
    Api.go('home_count',false,(res)=>{
      Vue.set(that,'homeCount',res)
    })
    Api.go('posts_by_topic',{id:4},(res)=>{
      that.homeboard=res[0]
    })
    if(sessionStorage.getItem('showad')){
      this.showad=false
    }else{
      this.showad=true
    }
    var ii=null;
    ii=setInterval(function(){
      if(that.showadi>0){
        that.showadi--;
      }else{
        that.hidead();
        clearInterval(ii)
      }
    },1000)
  }
}
</script>

<style lang="less" scoped>
.home{padding-bottom: 1.2rem;position: fixed;left: 0;top:0;width: 100%;height: 100%;box-sizing: border-box;}
.index-top{
    width: 100%;
    background: #fff;
    padding: .16rem .32rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top_fl{
        display: flex;
        align-items: center;
        font-size: .28rem;
        color: #333;
        font-weight: 400;
        img{
            width: .56rem;
            height: .64rem;
        }
    }
    .top_fr{
        display: flex;
        align-items: center;
        img{
            width: .36rem;
            height: .36rem;
        }
        div{
            padding: 0 .12rem;
            height: .8rem;
            display: flex;
            align-items: center;
        }
    }
}
.search{
    width: 100%;
    background: #fff;
    padding: .16rem .32rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .searchCon{
        width: 6rem;
        height: .8rem;
        background: #eee;
        display: flex;
        align-items: center;
        border-radius: .5rem;
        .searchBtn {
            padding:0 .2rem;
            height: 100%;
            display: flex;
            align-items: center;
           img{
                width: .36rem;
                height: .36rem;
           }
        }
        input{
            width: 4.8rem;
            font-size: .26rem;
            color: #666;
            height: 0.6rem;
            border: none;
            background: transparent;
            text-align: center;
        }
    }
    .cancelBtn{
        line-height: .6rem;
        font-size: .3rem;
        color: #333;
    }
}

.count-board{
    background:rgb(248,248,250);
    padding:0.32rem;
    table{
        width:100%;
        height: 1.36rem;
        background:#FEFEFE;
        border-radius: .16rem;
        text-align: center;
        td{
            font-size: .28rem;
            color: #666;
            font-weight: 400;
        }
        th{
            font-size: .32rem;
            color: #333;
            font-weight: 500;
        }
    }
}
.homelinks{
    background:rgb(248,248,250);
    padding:0 0.32rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.homelinks .link{
    width:3.26rem;height: 2.02rem;
    background:url(~@/assets/img/link1.png) no-repeat center center /cover;
    margin-bottom: .32rem;
}
.homelinks .link2{background-image:url(~@/assets/img/link2.png)}
.homelinks .link3{background-image:url(~@/assets/img/link3.png)}
.homelinks .link4{background-image:url(~@/assets/img/link4.png)}

.newpost-btn{position: fixed;display: block;
bottom: 1.4rem;right: 0.2rem;height: 1.14rem;width:1.14rem;
background: url(~@/assets/img/home-add.png) no-repeat center center/cover;}

.mask{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.erweima_main .erweima_wrap{
    width: 4.34rem;
    height: 4.58rem;
    background: #FFFFFF;
    border-radius: .24rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    div{
        width:100%;
        font-size: .32rem;
        font-weight: 400;
        color: #333333; 
        text-align: center;
    }
    img{
        width: 2.58rem;
        height: 2.58rem;
        background: rgba(216, 216, 216, 0);
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: .08rem;
        filter: blur(1px);
    }
    p{
        width: 100%;
        font-size: .28rem;
        font-weight: 400;
        color: #333333; 
        text-align: center;
        span{
            color: #F65102;
        }
    }
}

.closeBtn{
    width: .68rem;
    height: .68rem;
    margin: .54rem auto 0;
    img{
        width: 100%;
    }
}

.clock_main{
    width: 4.82rem;
    height: 6.08rem;
    background: url(~@/assets/img/clockBg.png) no-repeat center center/cover;
    margin-bottom: .64rem;
    .clock_tit{
        font-size: .36rem;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        padding-top: 3.12rem;
    }
    .clock_num{
        font-size: .28rem;
        color: #F6E5DA;
        text-align: center;
        margin: .24rem auto 0;
        p{
            display: flex;
            align-items: center;
            // justify-content: ;
            line-height: .5rem;
            margin-left: 1.22rem;
        }
        i{
            width: .32rem;
            height: .32rem;
            background: url(~@/assets/img/finish-ico.png) no-repeat center center/cover;
            display: block;
            margin-right: .15rem;
        }
    }
    .clock_txt{
        font-size: .24rem;
        color: #F6E5DA;
        text-align: center;
        margin-top: .66rem;
    }
}
.clockbtn{
    width: 4.82rem;
    height: .8rem;
    border-radius: .4rem;
    border: .02rem solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        width: .48rem;
        height: .38rem;
        margin-right: .15rem;
    }
    font-size: .32rem;
    color: #fff;
}
.btn1{
    margin-bottom: .48rem;
}

.home-ad{position: fixed;z-index: 999;
left: 0;top:0;background:#fff;width: 100%;height: 100%;}
.home-ad::before{height: 2.86rem;content: " ";
position:absolute;top:0;left: 0;width: 100%;
background:url(~@/assets/img/home-ad-t.png) no-repeat top center/cover;
}

.home-ad::after{height: 2.6rem;content: " ";
position:absolute;bottom:0;left: 0;width: 100%;
background:url(~@/assets/img/home-ad-b.png) no-repeat top center/cover;
}
.home-ad .icon1{
  margin-top:2rem;height: 2rem;position: relative;z-index: 2;
  background:url(~@/assets/img/home-ad-icon1.png) no-repeat top center/auto 100%;

}
.home-ad .icon2{
  margin-top:0.4rem;height: 0.27rem;
  background:url(~@/assets/img/home-ad-icon2.png) no-repeat top center/auto 100%;

}

.home-ad .icon3{
  height: 0.65rem;position: absolute;z-index: 3;
  bottom: 1rem;left: 50%;width: 2.6rem;margin-left: -1.3rem;
  background:url(~@/assets/img/home-ad-btn.png) no-repeat top center/auto 100%;

}
.home-ad .icon4{
  position: absolute;z-index: 3;
  right: .6rem;top:.6rem;height:.6rem;width: .6rem;
  background:#b2b2b2;color:#fff;
  border-radius: 0.6rem;text-align:center;line-height: .6rem;
  font-size: 0.3rem;

}
</style>